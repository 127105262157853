import {
  Box,
  Grid,
  Stack,
  Input,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import { coinHighscoresApi } from "../apis/coin-highscores";
import { Rings } from "react-loader-spinner";
import ProgressBar from "@ramonak/react-progress-bar";
import "./spy.css";
import { itemsHashed } from "../../const/const";
import { Inv } from "../components/inv";
import { CollectionLog } from "../components/collectionlog";
import { StatsPanel } from "../components/statspanel";
import { CommandHistory } from "../components/commandhistory";
import { PlayerXpGained } from "../components/player-xp-gained";

type userData = {
  username: any;
  coins: any;
  team: any;
  fishing: any;
  thieving: any;
  crafting: any;
  inv: any;
  coinsFormatted: any;
};

interface Props {
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}



export default function Spy({ setSelectedIndex }: Props) {

let getUsernameFromUrl = function(pageUrlHref) {
  var theArray  = pageUrlHref.split('?')
  var theArray2 = null
  var theArray3 = null
  var thePlayer = ""
  if(theArray.length >= 2){
  theArray2 = theArray[1].split('u=')
  if(theArray2.length >= 2){  
  theArray3 = theArray2[1]
  }
  if(theArray3 != null){
    thePlayer = theArray3
  }
}

if(thePlayer !== "")
{
  // console.log("thePlayer")
  // console.log(thePlayer)
}

return thePlayer


}

  function r13(str) {
    str = str.toUpperCase();
    return str.replace(/[A-Z]/g, rr13);

    function rr13(correspondance) {
      const charCode = correspondance.charCodeAt();
      //A = 65, Z = 90
      return String.fromCharCode(
        charCode + 13 <= 90 ? charCode + 13 : ((charCode + 13) % 90) + 64
      );
    }
  }

  const { pathname } = useLocation();

  var [usernamey, setUsernamey] = useState("whoopbang");
  var [viewType, setViewType] = useState(0);

  var [loading, setLoading] = useState(false);


  var [playerd, setPlayerd] = useState({
    username: null,
    coins: null,
    team: null,
    fishing: null,
    thieving: null,
    crafting: null,
    inv: null,
    coinsFormatted: null,
    invValueIfSold: null,
    invValueIfSoldFormatted: null,
    bankValueIfSold: null,
    bankValueIfSoldFormatted: null,
    collectionLog: [],
  });

  const handleTextChange = (event) => {
  //  setUsernamey(event.target.value);
  usernamey = event.target.value
  };


 

  const changeViewType = () => {
    if(viewType ==0){
      setViewType(1)
    }else {
      setViewType(0)
    }
      }

  const [playerDataRetrieved, error, loadings, axiosFetch] = useAxios();

  // batch processing done @ https://anytools.pro/en/img/editor

  // settings: -90 all

  // var itemsHashed = [];

  // for (var g = 0; g < itemsRaw.length; g++) {
  //   itemsHashed.push(r13(itemsRaw[g]));
  // }

  // console.log("hashed items list:");
  // console.log(itemsHashed);

  // console.log("hashed items list as string:");
  // console.log(itemsHashed.toString());

  useEffect(() => {
    setSelectedIndex(-1);
  }, [setSelectedIndex]);

  useEffect(() => {
    // action on update of movies
  }, [usernamey, ]);

  useEffect(() => {
    // action on update of movies
  }, [loading]);

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME!;
  }, [pathname]);

  const getData = () => {
    axiosFetch({
      axiosInstance: coinHighscoresApi,
      method: "get",
    });
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME!;
  }, [pathname]);


  const viewStrings = ["Icon View", "List View"]



  const handleClick = async (usernameyi) => {
    setLoading(true);
    // console.log("clicked");
    // console.log(usernamey);

    var userToUse = usernamey
    if(usernameyi && !usernameyi.target){
      userToUse=usernameyi
      // console.log("usernameyi")
      // console.log(usernameyi)
    }
    if(usernameyi && usernameyi.target){
      setUsernamey(usernamey)

    }
    
    try {
      const response = await fetch(
        "https://fishbot-backend.herokuapp.com/api/playerlookup/" +
        userToUse +
          "",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();

      // console.log("result");
      // console.log(result);
      setPlayerd(result);
    } catch (err) {
      //   setErr(err.message);
    } finally {
      setLoading(false);
    }
  };

  var playerDataObtained = playerDataRetrieved.data;
  // console.log("playerDataRetrieved");
  // console.log(playerDataRetrieved);

  var totals = {
    inv: playerd.invValueIfSoldFormatted,
    coins: playerd.coinsFormatted,
    total: playerd.bankValueIfSoldFormatted,
  };

  var totalLevel = 0;

  if (playerd.team != null) {
    totalLevel =
      playerd.fishing.level + playerd.thieving.level + playerd.crafting.level;
  }

  var collectionFoundLog = [];

  if (playerd.team !== null && playerd.collectionLog !== null) {
    for (var z = 0; z < itemsHashed.length; z++) {
      for (var aa = 0; aa < playerd.collectionLog.length; aa++) {
        if (r13(playerd.collectionLog[aa]) == itemsHashed[z]) {
          collectionFoundLog[z] = playerd.collectionLog[aa];
          break;
        } else {
          collectionFoundLog[z] = null;
        }
      }
    }
  }

  var thePlayer2 = getUsernameFromUrl(window.location.toString())


    useEffect(()=>{
      if(thePlayer2 !== "" && !playerd.username && !loading){
        var theEle = document.getElementById('inputSpyName')
        setUsernamey(thePlayer2);
        var g = () => {
          handleClick(thePlayer2.toString())
        }
        setTimeout(g,100)
      }
    }, []) // <-- empty dependency array



  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
    >
      <Grid item xs={1}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Box>
            <Typography variant="h3">Spy</Typography>
            <Typography variant="h6">
              Enter a players name (or your own) to see all their details!
            </Typography>
            <Box>
              <InputLabel>Spy on who?</InputLabel>
              <Input
                type="text"
                id="inputSpyName"
                className="inputSpyName"
                defaultValue={thePlayer2 || "whoopbang"}
                onChange={handleTextChange}
                // value={usernamey}
              />
              <Button
                className="spyButton"
                variant="outlined"
                onClick={handleClick}
              >
                Go
              </Button>
            </Box>
          </Box>
        </Stack>
      </Grid>
      {playerd.username && !loading ? (
        <Grid item xs={2}>
          <Box>
            <Typography variant="h3">{playerd.username}</Typography>
            <Typography variant="h6">{"Total level: " + totalLevel}</Typography>
          </Box>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            className={"spyStatsStack"}
          >
            <Box className="statsBox">
              <Grid
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-start" }}
                alignItems="center"
              >
                <Box className="fishingStatsPanelContainer">
                  <Grid
                    display="flex"
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                    alignItems="center"
                  >
                    <img
                      src="./IconFishing.png"
                      width="50px"
                      alt="iconFishing"
                      className="anIcon"
                    />
                    <Stack
                      direction={{ xs: "row", sm: "column" }}
                      spacing={0}
                      className="rightSideOfStatPanel"
                    >
                      <Typography variant="h6">Fishing</Typography>
                      <InputLabel>
                        {"Level: " + playerd.fishing.level}
                      </InputLabel>
                      <InputLabel>{"Exp: " + playerd.fishing.exp}</InputLabel>
                      <ProgressBar
                        completed={
                          playerd.fishing.percentUntilNextLevel == 0
                            ? 1
                            : playerd.fishing.percentUntilNextLevel
                        }
                        bgColor="#1a6661"
                        baseBgColor="#052422"
                      />
                      <InputLabel className="centerInputLabel">
                        {"" +
                          playerd.fishing.expUntilNextLevel +
                          " exp until level " +
                          (playerd.fishing.level + 1)}
                      </InputLabel>
                    </Stack>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-start" }}
                alignItems="center"
              >
                <Box className="thievingStatsPanelContainer">
                  <Grid
                    display="flex"
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                    alignItems="center"
                  >
                    <img
                      src="./IconThieving.png"
                      width="50px"
                      alt="iconFishing"
                    />
                    <Stack
                      direction={{ xs: "row", sm: "column" }}
                      spacing={0}
                      className="rightSideOfStatPanel"
                    >
                      <Typography variant="h6">Thieving</Typography>
                      <InputLabel>
                        {"Level: " + playerd.thieving.level}
                      </InputLabel>
                      <InputLabel>{"Exp: " + playerd.thieving.exp}</InputLabel>
                      <ProgressBar
                        completed={
                          playerd.thieving.percentUntilNextLevel == 0
                            ? 1
                            : playerd.thieving.percentUntilNextLevel
                        }
                        bgColor="#521a66"
                        baseBgColor="#29062e"
                      />
                      <InputLabel className="centerInputLabel">
                        {"" +
                          playerd.thieving.expUntilNextLevel +
                          " exp until level " +
                          (playerd.thieving.level + 1)}
                      </InputLabel>
                    </Stack>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-start" }}
                alignItems="center"
              >
                <Box className="craftingStatsPanelContainer">
                  <Grid
                    display="flex"
                    justifyContent={{ xs: "center", sm: "flex-start" }}
                    alignItems="center"
                  >
                    <img
                      src="./IconCrafting.png"
                      width="50px"
                      alt="iconFishing"
                    />
                    <Stack
                      direction={{ xs: "row", sm: "column" }}
                      spacing={0}
                      className="rightSideOfStatPanel"
                    >
                      <Typography variant="h6">Crafting</Typography>
                      <InputLabel>
                        {"Level: " + playerd.crafting.level}
                      </InputLabel>
                      <InputLabel>{"Exp: " + playerd.crafting.exp}</InputLabel>
                      <ProgressBar
                        completed={
                          playerd.crafting.percentUntilNextLevel == 0
                            ? 1
                            : playerd.crafting.percentUntilNextLevel
                        }
                        bgColor="#66271a"
                        baseBgColor="#2e0d06"
                      />
                      <InputLabel className="centerInputLabel">
                        {"" +
                          playerd.crafting.expUntilNextLevel +
                          " exp until level " +
                          (playerd.crafting.level + 1)}
                      </InputLabel>
                    </Stack>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Stack>
          <Box>
            <PlayerXpGained username={playerd.username}/>
          </Box>
          <Box>
            <CommandHistory username={playerd.username}/>
          </Box>
          <Box>
            <StatsPanel username={playerd.username}/>
          </Box>
          <Box>
              <Button className={'bbg'} onClick={changeViewType}>{viewStrings[viewType]}</Button>
              </Box>
          <Box>
            <Inv itemList={playerd.inv} totals={totals} view={viewType}></Inv>
          </Box>
          <Box>
            <CollectionLog
              itemList={itemsHashed}
              foundLog={collectionFoundLog}
              view={viewType}
            ></CollectionLog>
          </Box>
        </Grid>
      ) : loading ? (
        <Rings
          height="280"
          width="280"
          radius="14"
          color="grey"
          ariaLabel="loading"
        />
      ) : (
        <Box />
      )}
    </Grid>
  );
}
