import { Box, Grid, Stack, Typography } from "@mui/material";
import { Rings } from "react-loader-spinner";
import useAxios from "../hooks/useAxios";
import { useEffect, useState } from "react";
import { UiControls } from "./uicontrols";
import { playerXpGainedApi } from "../apis/player-xpgained";
import PlayerExGainedGraphComponent from "./player-xp-gained-graphs";

export const PlayerXpGained = (incomingObj) => {

    var username = incomingObj.username

    const [playerXpGainedRes, error, loading, axiosFetch] = useAxios();

    var [statsState, setStatsState] = useState('closed');


    const handleExpand = (whatIs) => {
        setStatsState(whatIs)
    }


    const handleRefresh = () => {
        getData()
    }

    const getData = () => {
        axiosFetch({
            axiosInstance: playerXpGainedApi(username),
            method: "get",
        });
    };

    useEffect(() => {
        // action on update of movies
    }, [loading]);

    useEffect(() => {
        getData();
    }, []);

    if (statsState == "open") {
        if (loading) {
            return (
                <Rings
                    height="180"
                    width="180"
                    radius="6"
                    color="grey"
                    ariaLabel="loading"
                />
            );
        } else {
            return (
                <Box className="inv">
                    <Box className="invTitleHolder">
                        <Stack>
                            <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
                                <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
                                    Xp Gained:
                                </Typography>
                                <UiControls expand={handleExpand} refresh={handleRefresh} />
                            </Grid>
                        </Stack>
                    </Box>
                    <Grid
                        display="flex"
                        justifyContent={{ xs: "center", sm: "flex-start" }}
                        alignItems="center"
                    >
                        {!loading ? (
                            <PlayerExGainedGraphComponent playerXpGainedList={playerXpGainedRes}></PlayerExGainedGraphComponent>
                        ) : (
                            <Box className="loady">
                                <Rings
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="grey"
                                    ariaLabel="loading"
                                />
                            </Box>
                        )}
                    </Grid>
                </Box>
            );
        }
    } else {
        return (
            <Box className="inv">
                <Box className="invTitleHolder">
                    <Stack>
                        <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
                            <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
                                Xp Gained:
                            </Typography>
                            <UiControls expand={handleExpand} refresh={handleRefresh} />
                        </Grid>
                    </Stack>
                </Box>
            </Box>
        );
    }
};