import './chat.css';
import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import "./player-xp-gained-graphs.css"

export interface PlayerXpGained {
  username: string;
  fishExpGained: number;
  thieveExpGained: number;
  craftExpGained: number;
  date: string;
}

interface Props {
  playerXpGainedList: PlayerXpGained[];
}

const PlayerExGainedGraphComponent = ({ playerXpGainedList }) => {
    const reversedXpGainedList = [...playerXpGainedList].reverse(); 
    const [xpType, setXpType] = useState("craftExpGained");

    const getBarColor = (xpType) => {
        switch (xpType) {
            case "craftExpGained":
                return "#edb861"; 
            case "fishExpGained":
                return "#89e9fa"; 
            case "thieveExpGained":
                return "#bb6de8"; 
            default:
                return "#8884d8"; 
        }
    };

    const getBarName = (xpType) => {
        switch (xpType) {
            case "craftExpGained":
                return "Craft XP Gained";
            case "fishExpGained":
                return "Fish XP Gained";
            case "thieveExpGained":
                return "Thieve XP Gained";
            default:
                return "XP Gained";
        }
    };

    const handleLeftArrowClick = () => {
        if (xpType === "craftExpGained") {
            setXpType("fishExpGained"); 
        } else if (xpType === "fishExpGained") {
            setXpType("thieveExpGained"); 
        } else {
            setXpType("craftExpGained");
        }
    };

    const handleRightArrowClick = () => {
        if (xpType === "craftExpGained") {
            setXpType("fishExpGained"); 
        } else if (xpType === "fishExpGained") {
            setXpType("thieveExpGained"); 
        } else {
            setXpType("craftExpGained");
        }
    };

    return (
        <div className="graph-container">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={reversedXpGainedList}
                    margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tick={{ fill: '#75fa97', fontSize: 12 }} interval={0} angle={-45} textAnchor="end" />
                    <YAxis tick={{ fill: '#75fa97', fontSize: 12 }} />
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={40} wrapperStyle={{ paddingTop: '40px' }} />
                    <Bar 
                        name={getBarName(xpType)} 
                        dataKey={xpType} 
                        fill={getBarColor(xpType)} 
                    />
                </BarChart>
            </ResponsiveContainer>

            <div className="arrow-buttons" style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={handleLeftArrowClick} style={{ marginRight: '10px', fontSize: '20px' }}>
                    &#8592; {/* Left Arrow */}
                </button>
                <button onClick={handleRightArrowClick} style={{ fontSize: '20px' }}>
                    &#8594; {/* Right Arrow */}
                </button>
            </div>
        </div>
    );
};

export default PlayerExGainedGraphComponent;
