import {
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import logo from "../../static/favicon.png";
import { useLocation } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import { links } from "./links";
import { coinHighscoresApi } from "../apis/coin-highscores";
import { fishHighscoresApi } from "../apis/fish-highscores";
import { thieveHighscoresApi } from "../apis/thieve-highscores";
import { craftHighscoresApi } from "../apis/craft-highscores";
import { totalHighscoresApi } from "../apis/total-highscores";
import { colHighscoresApi } from "../apis/col-highscores";
import { neptuneHighscoresApi } from "../apis/neptune-highscores";
import { posidonHighscoresApi } from "../apis/posidon-highscores";

import { TableComponent } from "../components/table";
import { captureRejectionSymbol } from "events";
import { Rings } from "react-loader-spinner";

import "./highscores.css";
interface Props {
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function Highscores({ setSelectedIndex }: Props) {
  const { pathname } = useLocation();

  const [coinsHighscores, error, loading, axiosFetch] = useAxios();

  const [fishHighscores, errorF, loadingF, axiosFetchF] = useAxios();
  const [thieveHighscores, errorT, loadingT, axiosFetchT] = useAxios();
  const [craftHighscores, errorC, loadingC, axiosFetchC] = useAxios();
  const [totalHighscores, errorD, loadingD, axiosFetchD] = useAxios();
  const [colHighscores, errorE, loadingE, axiosFetchE] = useAxios();
  const [neptuneHighscores, errorNep, loadingNep, axiosFetchNep] = useAxios();
  const [posidonHighscores, errorPos, loadingPos, axiosFetchPos] = useAxios();

  useEffect(() => {
    setSelectedIndex(-1);
  }, [setSelectedIndex]);

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME!;
  }, [pathname]);

  const getData = () => {
    axiosFetch({
      axiosInstance: coinHighscoresApi,
      method: "get",
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getDataFish = () => {
    axiosFetchF({
      axiosInstance: fishHighscoresApi,
      method: "get",
    });
  };

  useEffect(() => {
    getDataFish();
  }, []);

  const getDataThieve = () => {
    axiosFetchT({
      axiosInstance: thieveHighscoresApi,
      method: "get",
    });
  };

  useEffect(() => {
    getDataThieve();
  }, []);

  const getDataCraft = () => {
    axiosFetchC({
      axiosInstance: craftHighscoresApi,
      method: "get",
    });
  };

  
  const getDataTotal = () => {
    axiosFetchD({
      axiosInstance: totalHighscoresApi,
      method: "get",
    });
  };

  const getDataCol = () => {
    axiosFetchE({
      axiosInstance: colHighscoresApi,
      method: "get",
    });
  };


  useEffect(() => {
    getDataCraft();
  }, []);

  useEffect(() => {
    getDataTotal();
  }, []);

  useEffect(() => {
    getDataCol();
  }, []);

  const getDataNeptune = () => {
    axiosFetchNep({
      axiosInstance: neptuneHighscoresApi,
      method: "get",
    });
  };

  const getDataPosidon = () => {
    axiosFetchPos({
      axiosInstance: posidonHighscoresApi,
      method: "get",
    });
  };

  useEffect(() => {
    getDataNeptune();
  }, []);

  useEffect(() => {
    getDataPosidon();
  }, []);



  // coins
  var aCopyOfCoins = coinsHighscores.toString();

  // console.log("aCopyOfCoins");
  // console.log(aCopyOfCoins);

  var data = [
    {
      Rank: "Marcel",
      Player: "Michau",
      Coins: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Coins: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Coins: "24",
    },
  ];

  var tableData = aCopyOfCoins.split("<br>");
  var tempArray = [];
  var trueOutput = [];
  for (var g = 0; g < tableData.length - 1; g++) {
    tempArray = tableData[g].split(" ");
    trueOutput.push({ Rank: g + 1, Player: tempArray[1], Coins: tempArray[3] });
  }
  // console.log(tableData);
  data = trueOutput;

  //fish
  aCopyOfCoins = fishHighscores.toString();

  // console.log("fishing:");
  // console.log(aCopyOfCoins);

  var dataFish = [
    {
      Rank: "Marcel",
      Player: "Michau",
      Coins: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Coins: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Coins: "24",
    },
  ];

  var tableData = aCopyOfCoins.split("<br>");
  var tempArray = [];
  var trueOutput = [];
  for (var g = 0; g < tableData.length - 1; g++) {
    tempArray = tableData[g].split(" ");
    trueOutput.push({ Rank: g + 1, Player: tempArray[1], Level: tempArray[3] });
  }
  // console.log(tableData);
  dataFish = trueOutput;

  // Thieving
  aCopyOfCoins = thieveHighscores.toString();

  // console.log("thieve:");
  // console.log(aCopyOfCoins);

  var dataThieve = [
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
  ];

  var tableData = aCopyOfCoins.split("<br>");
  var tempArray = [];
  var trueOutput = [];
  for (var g = 0; g < tableData.length - 1; g++) {
    tempArray = tableData[g].split(" ");
    trueOutput.push({ Rank: g + 1, Player: tempArray[1], Level: tempArray[3] });
  }
  // console.log(tableData);
  dataThieve = trueOutput;

  // Crafting
  aCopyOfCoins = craftHighscores.toString();

  // console.log("craft:");
  // console.log(aCopyOfCoins);

  var dataCraft = [
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
  ];

  var tableData = aCopyOfCoins.split("<br>");
  var tempArray = [];
  var trueOutput = [];
  for (var g = 0; g < tableData.length - 1; g++) {
    tempArray = tableData[g].split(" ");
    trueOutput.push({ Rank: g + 1, Player: tempArray[1], Level: tempArray[3] });
  }
  // console.log(tableData);
  dataCraft = trueOutput;










  // TotalLvl
 var aCopyOfTotal = totalHighscores.toString();

  // console.log("craft:");
  // console.log(aCopyOfCoins);

  var dataTotal = [
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Level: "24",
    },
  ];

  var tableData = aCopyOfTotal.split("<br>");
  var tempArrays = [];
  var trueOutput22 = [];
  for (var g = 0; g < tableData.length - 1; g++) {
    tempArrays = tableData[g].split(" ");
    trueOutput22.push({ Rank: g + 1, Player: tempArrays[1], Level: tempArrays[3] });
  }
  // console.log(tableData);
  dataTotal = trueOutput22;



















  // colLog
  var aCopyOfTotalCol = colHighscores.toString();

  //console.log("aCopyOfTotalCol")
 // console.log(colHighscores)

  // console.log("craft:");
  // console.log(aCopyOfCoins);

  var dataCol = [
    {
      Rank: "Marcel",
      Player: "Michau",
      Count: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Count: "24",
    },
    {
      Rank: "Marcel",
      Player: "Michau",
      Count: "24",
    },
  ];

  var tableDataCol = aCopyOfTotalCol.split("<br>");
  var tempArraysxx = [];
  var trueOutput22 = [];
  for (var g = 0; g < tableDataCol.length - 1; g++) {
    tempArraysxx = tableDataCol[g].split(" ");
    trueOutput22.push({ Rank: g + 1, Player: tempArraysxx[1], Count: tempArraysxx[3] });
  }
  // console.log(tableData);
  dataCol = trueOutput22;




























  
  // neptune
  var aCopyOfTotalNeptune = neptuneHighscores

  console.log('nep')
console.log(aCopyOfTotalNeptune)

  var neptuneData = []

 
  for (var g = 0; g < aCopyOfTotalNeptune.length - 1; g++) {
    neptuneData.push({ Rank: g + 1, Player: aCopyOfTotalNeptune[g].user, Kc: aCopyOfTotalNeptune[g].value });
  }


  // posidon
  var aCopyOfTotalPosidon = posidonHighscores

  var posidonData = []

 
  for (var g = 0; g < aCopyOfTotalPosidon.length - 1; g++) {
    posidonData.push({ Rank: g + 1, Player: aCopyOfTotalPosidon[g].user, Kc: aCopyOfTotalPosidon[g].value });
  }





  if (dataThieve.length < 10) {
    for (var p = dataThieve.length - 1; p < 10; p++) {
      dataThieve.push({ Rank: (p + 1).toString(), Player: "-", Level: "-" });
    }
  }

  if (dataCraft.length < 10) {
    for (var p = dataCraft.length - 1; p < 10; p++) {
      dataCraft.push({ Rank: (p + 1).toString(), Player: "-", Level: "-" });
    }
  }

  if(neptuneData.length < 10){
    for (var p = neptuneData.length - 1; p < 10; p++) {
      neptuneData.push({ Rank: (p + 1).toString(), Player: "-", Kc: "-" });
    }
  }
  if(posidonData.length < 10){
    for (var p = posidonData.length - 1; p < 10; p++) {
      posidonData.push({ Rank: (p + 1).toString(), Player: "-", Kc: "-" });
    }
  }



  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
    >
      <Grid item xs={1}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Box>
            <Typography variant="h3">Highscores</Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Box className="coinsBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Coins</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
              alignItems="center"
            >
              {!loading ? (
                <TableComponent data={data}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
          <Box className="fishingBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Fishing</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              {!loadingF ? (
                <TableComponent data={dataFish}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        {" "}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          className="secondSet"
        >
          <Box className="thievingBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Thieving</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              {!loadingT ? (
                <TableComponent data={dataThieve}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
          <Box className="craftingBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Crafting</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              {!loadingC ? (
                <TableComponent data={dataCraft}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          className="secondSet"
        >

<Box className="totalBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Total Level</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              {!loadingD ? (
                <TableComponent data={dataTotal}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
          <Box className="colBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Collection Log</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              {!loadingE ? (
                <TableComponent data={dataCol}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
          </Stack>


          <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          className="thirdSet"
        >

<Box className="neptuneBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Neptune Kills</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              {!loadingNep ? (
                <TableComponent data={neptuneData}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
          <Box className="posidonBox">
            <Grid
              display="flex"
              justifyContent={{ xs: "center" }}
              className="titleBg"
            >
              <Typography variant="h5">Posidon Kills</Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              {!loadingPos ? (
                <TableComponent data={posidonData}></TableComponent>
              ) : (
                <Box className="loady">
                  <Rings
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="loading"
                  />
                </Box>
              )}
            </Grid>
          </Box>
          </Stack>


          <br></br>
<br></br>
      </Grid>
    </Grid>
  );
}
